import React from "react";
import education from "../../../../assets/images/home/education.svg";
import government from "../../../../assets/images/home/government.svg";
import financial from "../../../../assets/images/home/financial.svg";
import healtcare from "../../../../assets/images/home/healtcare.svg";
import manufacturing from "../../../../assets/images/home/manufacturing.svg";
import retail from "../../../../assets/images/home/retail.svg";
import { motion } from "framer-motion";
import Marquee from "react-fast-marquee";

const securityServices = {
  top: [
    { name: "CHURCH SECURITY" },
    { name: "SHOP SECURITY" },
    { name: "MALL SECURITY" },
    { name: "CONSTRUCTION SITE WATCH" },
    { name: "WAREHOUSE WATCH" },
    { name: "APARTMENT BUILDING SECURITY" },
    { name: "BODY SECURITY" },
    { name: "EXECUTIVE PROTECTION" },
    { name: "PERSONAL SECURITY" },
    { name: "SECURITY MANAGER" },
    { name: "SECURITY SUPERVISOR" },
    { name: "SECURITY CONSULTANT" },
    { name: "OFFICE BUILDING SECURITY" },
    { name: "SCHOOL SECURITY" },
  ],
  bottom: [
    { name: "APARTMENT BUILDING SECURITY" },
    { name: "BODY SECURITY" },
    { name: "EXECUTIVE PROTECTION" },
    { name: "PERSONAL SECURITY" },
    { name: "SECURITY MANAGER" },
    { name: "SECURITY SUPERVISOR" },
    { name: "SECURITY CONSULTANT" },
    { name: "OFFICE BUILDING SECURITY" },
    { name: "SCHOOL SECURITY" },
    { name: "HOSPITAL SECURITY" },
    { name: "CLUB BOUNCER" },
    { name: "EVENT DOOR STAFF" },
    { name: "PUB SECURITY" },
    { name: "CONCERT SECURITY" },
  ],
};

const sectors = [
  { image: education, name: "Education" },
  { image: financial, name: "Financial Services" },
  { image: government, name: "Government" },
  { image: healtcare, name: "Healthcare" },
  { image: manufacturing, name: "Manufacturing" },
  { image: retail, name: "Retail" },
];

const _inital = {
  y: "20%",
  opacity: 0,
  scale: 0.8,
};

const _whileInView = {
  y: 1,
  opacity: 1,
  scale: 1,
  transition: { duration: 0.8 },
};

const _viewport = { once: true };

const _initialLeft = { x: "-40%", opacity: 0 };
const _whileInViewLeft = {
  x: 0,
  opacity: 1,
  transition: {
    delay: 0.2,
    duration: 0.8,
  },
};

const PopularTrade = () => {
  return (
    <>
      <div
        className={`w-full   py-[50px] sm:py-[100px] mt-[40px] sm:mt-[70px] bg-[#F5FAFF]`}
      >
        <div className="px-[20px]">
          <div className="max-w-[1340px] mx-auto">
            <motion.h4
              initial={_inital}
              whileInView={_whileInView}
              viewport={_viewport}
              className="text-[#2065EC] text-center font-[600] leading-[26px] text-[16px] mx-auto mb-[12px]"
            >
              OVER 20+ TRADES
            </motion.h4>

            <motion.h1
              initial={_inital}
              whileInView={_whileInView}
              viewport={_viewport}
              className="text-[#040815] text-center font-[600] leading-[60px] text-[40px] mx-auto mb-[20px]"
            >
              Popular Trades on igardu
            </motion.h1>

            <motion.p
              initial={_inital}
              whileInView={_whileInView}
              viewport={_viewport}
              className="text-[#596780] text-center font-[400] leading-[30px] text-[18px] max-w-[569px] mx-auto mb-[55px]"
            >
              Our platform provides you access to various trades and craftsmen
              throughout the UK.
            </motion.p>
          </div>
        </div>

        <div className="pb-[80px] ">
          <Marquee className={"relative  overflow-x-hidden"}>
            <div className="flex bg-red translate-x-[-30px]">
              {securityServices.top.map((x, index) => (
                <div
                  key={`${index}`}
                  className="w-[160px] mx-[13px] h-[98px] shrink-0 text-center border border-[#aaaaaa] rounded-[10px] text-[14px] text-[#1E293B] flex items-center justify-center"
                >
                  <span className="text-inherit">{x?.name}</span>
                </div>
              ))}
            </div>
          </Marquee>

          <Marquee className={"relative  overflow-x-hidden"} direction="right">
            <div className="flex bg-red translate-x-[-30px] mt-[16px]">
              {securityServices.bottom.map((x, index) => (
                <div
                  key={`${index}`}
                  className="w-[160px] h-[98px] mx-[13px]   shrink-0 text-center border border-[#aaaaaa] rounded-[10px] text-[14px] text-[#1E293B] flex items-center justify-center"
                >
                  <span className="text-inherit">{x?.name}</span>
                </div>
              ))}
            </div>
          </Marquee>
        </div>
        <div className="px-[20px]">
          <div className="max-w-[1240px] mx-auto flex flex-col lg:flex-row gap-[32px] lg:gap-0 pt-[0px] sm:pt-[80px] items-center justify-between">
            <div className="w-full max-w-[500px]">
              <motion.p
                initial={_initialLeft}
                whileInView={_whileInViewLeft}
                viewport={_viewport}
                className="text-[#000000] text-center lg:text-left text-[30px] leading-[35px] font-[600px] mb-[32px] lg:mb-[41px]"
              >
                Protecting organisations across industries and the UK{" "}
              </motion.p>
              <motion.p
                initial={_initialLeft}
                whileInView={_whileInViewLeft}
                viewport={_viewport}
                className="text-[#000000] text-center lg:text-left text-[20px] leading-[30px]  font-[400px]"
              >
                Hundreds of thousands of small businesses have found new
                customers on Iguardu{" "}
              </motion.p>
            </div>

            <div className="flex flex-wrap gap-[24px] justify-center lg:justify-end">
              {sectors.map((x, index) => {
                return (
                  <motion.div
                    key={index}
                    initial={_inital}
                    whileInView={{
                      ..._whileInView,
                      transition: {
                        duration: 0.8,
                        delay: 0.1 * index,
                      },
                    }}
                    viewport={_viewport}
                    className="gap-[16px] w-full h-[175px] sm:w-[272px] sm:h-[138px] flex flex-col items-center justify-center border border-[#AAAAAA] rounded-[16px] bg-white"
                  >
                    <img
                      src={x.image}
                      alt="sector_icon"
                      className="w-[60px] h-[60px]"
                    />

                    <span className="text-[#000000] text-[18px] leading-[22px] text-center ">
                      {x.name}
                    </span>
                  </motion.div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="lg:px-0 lg:py-24 px-9 py-8 bg-[#F5FAFF] ">
      <div className="text-[#145DEB] mb-4 text-center lg:text-base text-xs">
      OVER 20+ TRADES
      </div>
      <div className="lg:text-[40px] text-2xl text-center font-semibold mb-3">
      Popular Trades on igardu
      </div>
      <div className="lg:text-lg text-base text-[#596780] mb-14 lg:w-1/3 mx-auto text-center">
      Our platform provides you access to various trades and craftsmen throughout the UK.
      </div>
      <div className="grid lg:grid-cols-10 grid-cols-2  gap-4">
        <div className="px-4 py-8 rounded-lg border border-neutral-400 text-center text-slate-500 text-xs font-medium">
          DOOR SECURITY
        </div>
        <div className="px-4 py-8 rounded-lg border border-neutral-400 text-center text-slate-500 text-xs font-medium">
          DOOR SECURITY
        </div>
        <div className="px-4 py-8 rounded-lg border border-neutral-400 text-center text-slate-500 text-xs font-medium">
          DOOR SECURITY
        </div>
        <div className="px-4 py-8 rounded-lg border border-neutral-400 text-center text-slate-500 text-xs font-medium">
          KEY HOLDERS SECURITY
        </div>
        <div className="px-4 py-8 rounded-lg border border-neutral-400 text-center text-slate-500 text-xs font-medium">
          DOOR SECURITY
        </div>
        <div className="px-4 py-8 rounded-lg border border-neutral-400 text-center text-slate-500 text-xs font-medium">
          DOOR SECURITY
        </div>
        <div className="px-4 py-8 rounded-lg border border-neutral-400 text-center text-slate-500 text-xs font-medium">
          KEY HOLDERS SECURITY
        </div>
        <div className="px-4 py-8 rounded-lg border border-neutral-400 text-center text-slate-500 text-xs font-medium">
          KEY HOLDERS SECURITY
        </div>
        <div className="px-4 py-8 rounded-lg border border-neutral-400 text-center text-slate-500 text-xs font-medium">
          KEY HOLDERS SECURITY
        </div>
        <div className="px-4 py-8 rounded-lg border border-neutral-400 text-center text-slate-500 text-xs font-medium">
          DOOR SECURITY
        </div>
        <div className="px-4 py-8 rounded-lg border border-neutral-400 text-center text-slate-500 text-xs font-medium">
          KEY HOLDERS SECURITY
        </div>
        <div className="px-4 py-8 rounded-lg border border-neutral-400 text-center text-slate-500 text-xs font-medium">
          KEY HOLDERS SECURITY
        </div>
        <div className="px-4 py-8 rounded-lg border border-neutral-400 text-center text-slate-500 text-xs font-medium">
          DOOR SECURITY
        </div>
        <div className="px-4 py-8 rounded-lg border border-neutral-400 text-center text-slate-500 text-xs font-medium">
          DOOR SECURITY
        </div>
        <div className="px-4 py-8 rounded-lg border border-neutral-400 text-center text-slate-500 text-xs font-medium">
          DOOR SECURITY
        </div>
        <div className="px-4 py-8 rounded-lg border border-neutral-400 text-center text-slate-500 text-xs font-medium">
          KEY HOLDERS SECURITY
        </div>
        <div className="px-4 py-8 rounded-lg border border-neutral-400 text-center text-slate-500 text-xs font-medium">
          KEY HOLDERS SECURITY
        </div>
        <div className="px-4 py-8 rounded-lg border border-neutral-400 text-center text-slate-500 text-xs font-medium">
          DOOR SECURITY
        </div>
        <div className="px-4 py-8 rounded-lg border border-neutral-400 text-center text-slate-500 text-xs font-medium">
          DOOR SECURITY
        </div>
        <div className="px-4 py-8 rounded-lg border border-neutral-400 text-center text-slate-500 text-xs font-medium">
          KEY HOLDERS SECURITY
        </div>
      </div>
    </div> */}
    </>
  );
};

export default PopularTrade;

{
  /*
import React from "react";

const PopularTrade = () => {
  return (
    <div className="mx-auto w-screen h-[666px] bg-slate-50">
      <div className="max-w-[1440px] relative mx-auto w-screen">
        <div className="h-[95px] left-[1482px] top-[141.20px] absolute flex-col justify-center items-center gap-3 inline-flex">
          <div className="h-[60px] flex-col justify-center items-center gap-2 flex">
            <div className="w-[60px] justify-center items-start inline-flex">
              <div className="w-[60px] h-[60px] relative">
                <div className="w-[32.84px] h-[33.80px] left-[13.50px] top-[12px] absolute" />
              </div>
            </div>
          </div>
        </div>
        <div className="left-[430.50px] top-[91px] absolute flex-col justify-center items-center gap-[11px] inline-flex">
          <div className="h-[102px] flex-col justify-start items-center gap-4 flex">
            <div className="w-[185px] text-center text-blue-600 text-[15px] font-medium font-['Instrument Sans'] capitalize leading-relaxed tracking-widest">
              OVER 20+ TRADES
            </div>
            <div className="text-center text-gray-950 text-[40px] font-semibold font-['Neue Haas Grotesk Display Pro'] leading-[60px]">
              Popular Trades on igardu
            </div>
          </div>
          <div className="w-[456px] opacity-60 text-center text-slate-500 text-lg font-normal font-['Instrument Sans'] leading-[27px]">
            Our platform provides you access to various trades and craftsmen
            throughout the UK.
          </div>
        </div>
         <div className="w-[1472px] left-[-30px] top-[313px] absolute justify-start items-center gap-[17.32px] inline-flex">
        <div className="w-[131.63px] h-[108.25px] px-[4.33px] rounded-lg border border-neutral-400 flex-col justify-center items-center gap-[15px] inline-flex">
            <div className="w-[102px] h-[45px] text-center text-slate-500 text-xs font-medium font-['Instrument Sans'] leading-[25px] tracking-widest">DOOR SECURITY</div>
        </div>
        <div className="w-[131.63px] h-[108.25px] px-[4.33px] rounded-lg border border-neutral-400 flex-col justify-center items-center gap-[15px] inline-flex">
            <div className="w-[102px] h-[45px] text-center text-slate-500 text-xs font-medium font-['Instrument Sans'] leading-[25px] tracking-widest">DOOR SECURITY</div>
        </div>
        <div className="w-[131.63px] h-[108.25px] px-[4.33px] rounded-lg border border-neutral-400 flex-col justify-center items-center gap-[15px] inline-flex">
            <div className="w-[102px] h-[45px] text-center text-slate-500 text-xs font-medium font-['Instrument Sans'] leading-[25px] tracking-widest">DOOR SECURITY</div>
        </div>
        <div className="w-[131.63px] h-[108.25px] px-[4.33px] rounded-lg border border-neutral-400 flex-col justify-center items-center gap-[15px] inline-flex">
            <div className="w-[102px] h-[45px] text-center text-slate-500 text-xs font-medium font-['Instrument Sans'] leading-[25px] tracking-widest">KEY HOLDERS SECURITY</div>
        </div>
        <div className="w-[131.63px] h-[108.25px] px-[4.33px] rounded-lg border border-neutral-400 flex-col justify-center items-center gap-[15px] inline-flex">
            <div className="w-[102px] h-[45px] text-center text-slate-500 text-xs font-medium font-['Instrument Sans'] leading-[25px] tracking-widest">DOOR SECURITY</div>
        </div>
        <div className="w-[131.63px] h-[108.25px] px-[4.33px] rounded-lg border border-neutral-400 flex-col justify-center items-center gap-[15px] inline-flex">
            <div className="w-[102px] h-[45px] text-center text-slate-500 text-xs font-medium font-['Instrument Sans'] leading-[25px] tracking-widest">DOOR SECURITY</div>
        </div>
        <div className="w-[131.63px] h-[108.25px] px-[4.33px] rounded-lg border border-neutral-400 flex-col justify-center items-center gap-[15px] inline-flex">
            <div className="w-[102px] h-[45px] text-center text-slate-500 text-xs font-medium font-['Instrument Sans'] leading-[25px] tracking-widest">KEY HOLDERS SECURITY</div>
        </div>
        <div className="w-[131.63px] h-[108.25px] px-[4.33px] rounded-lg border border-neutral-400 flex-col justify-center items-center gap-[15px] inline-flex">
            <div className="w-[102px] h-[45px] text-center text-slate-500 text-xs font-medium font-['Instrument Sans'] leading-[25px] tracking-widest">KEY HOLDERS SECURITY</div>
        </div>
        <div className="w-[131.63px] h-[108.25px] px-[4.33px] rounded-lg border border-neutral-400 flex-col justify-center items-center gap-[15px] inline-flex">
            <div className="w-[102px] h-[45px] text-center text-slate-500 text-xs font-medium font-['Instrument Sans'] leading-[25px] tracking-widest">KEY HOLDERS SECURITY</div>
        </div>
        <div className="w-[131.63px] h-[108.25px] px-[4.33px] rounded-lg border border-neutral-400 flex-col justify-center items-center gap-[15px] inline-flex">
            <div className="w-[102px] h-[45px] text-center text-slate-500 text-xs font-medium font-['Instrument Sans'] leading-[25px] tracking-widest">KEY HOLDERS SECURITY</div>
        </div>
    </div> */
}
{
  /* <div className="w-[1472px] left-[-16px] top-[456px] absolute justify-start items-center gap-[17.32px] inline-flex">
        <div className="w-[131.63px] h-[108.25px] px-[4.33px] rounded-lg border border-neutral-400 flex-col justify-center items-center gap-[15px] inline-flex">
            <div className="w-[102px] h-[45px] text-center text-slate-500 text-xs font-medium font-['Instrument Sans'] leading-[25px] tracking-widest">DOOR SECURITY</div>
        </div>
        <div className="w-[131.63px] h-[108.25px] px-[4.33px] rounded-lg border border-neutral-400 flex-col justify-center items-center gap-[15px] inline-flex">
            <div className="w-[102px] h-[45px] text-center text-slate-500 text-xs font-medium font-['Instrument Sans'] leading-[25px] tracking-widest">DOOR SECURITY</div>
        </div>
        <div className="w-[131.63px] h-[108.25px] px-[4.33px] rounded-lg border border-neutral-400 flex-col justify-center items-center gap-[15px] inline-flex">
            <div className="w-[102px] h-[45px] text-center text-slate-500 text-xs font-medium font-['Instrument Sans'] leading-[25px] tracking-widest">DOOR SECURITY</div>
        </div>
        <div className="w-[131.63px] h-[108.25px] px-[4.33px] rounded-lg border border-neutral-400 flex-col justify-center items-center gap-[15px] inline-flex">
            <div className="w-[102px] h-[45px] text-center text-slate-500 text-xs font-medium font-['Instrument Sans'] leading-[25px] tracking-widest">KEY HOLDERS SECURITY</div>
        </div>
        <div className="w-[131.63px] h-[108.25px] px-[4.33px] rounded-lg border border-neutral-400 flex-col justify-center items-center gap-[15px] inline-flex">
            <div className="w-[102px] h-[45px] text-center text-slate-500 text-xs font-medium font-['Instrument Sans'] leading-[25px] tracking-widest">DOOR SECURITY</div>
        </div>
        <div className="w-[131.63px] h-[108.25px] px-[4.33px] rounded-lg border border-neutral-400 flex-col justify-center items-center gap-[15px] inline-flex">
            <div className="w-[102px] h-[45px] text-center text-slate-500 text-xs font-medium font-['Instrument Sans'] leading-[25px] tracking-widest">DOOR SECURITY</div>
        </div>
        <div className="w-[131.63px] h-[108.25px] px-[4.33px] rounded-lg border border-neutral-400 flex-col justify-center items-center gap-[15px] inline-flex">
            <div className="w-[102px] h-[45px] text-center text-slate-500 text-xs font-medium font-['Instrument Sans'] leading-[25px] tracking-widest">KEY HOLDERS SECURITY</div>
        </div>
        <div className="w-[131.63px] h-[108.25px] px-[4.33px] rounded-lg border border-neutral-400 flex-col justify-center items-center gap-[15px] inline-flex">
            <div className="w-[102px] h-[45px] text-center text-slate-500 text-xs font-medium font-['Instrument Sans'] leading-[25px] tracking-widest">KEY HOLDERS SECURITY</div>
        </div>
        <div className="w-[131.63px] h-[108.25px] px-[4.33px] rounded-lg border border-neutral-400 flex-col justify-center items-center gap-[15px] inline-flex">
            <div className="w-[102px] h-[45px] text-center text-slate-500 text-xs font-medium font-['Instrument Sans'] leading-[25px] tracking-widest">KEY HOLDERS SECURITY</div>
        </div>
        <div className="w-[131.63px] h-[108.25px] px-[4.33px] rounded-lg border border-neutral-400 flex-col justify-center items-center gap-[15px] inline-flex">
            <div className="w-[102px] h-[45px] text-center text-slate-500 text-xs font-medium font-['Instrument Sans'] leading-[25px] tracking-widest">KEY HOLDERS SECURITY</div>
        </div>
    </div> 
        
      </div>
    </div>
    );
  };
  
  export default PopularTrade;
*/
}
