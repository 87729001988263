import { TextField } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const ProcessFive = () => {
  const [isCheck, setIsCheck] = useState(false);

  return (
    <div className="max-w-5xl mx-auto w-full max-md:px-2 my-10">
      <div className="w-full flex justify-between flex-wrap">
        <div className="w-6/12 max-md:w-full px-2 my-2">
          <label htmlFor="" className="text-sm text-secondary font-medium">
            First Name*
          </label>
          <Field
            type="text"
            variant="outlined"
            placeholder="First Name"
            name="first_name"
            className="w-full p-2 px-4 mb-3 mt-1 focus:outline-primary rounded-xl border border-gray-300"
          />
          <div className="text-sm text-red-500">
            <ErrorMessage name="first_name" />
          </div>
        </div>
        <div className="w-6/12 max-md:w-full px-2 my-2">
          <label htmlFor="" className="text-sm text-secondary font-medium">
            Last Name*
          </label>
          <Field
            name="last_name"
            type="text"
            variant="outlined"
            placeholder="Last Name"
            className="w-full p-2 px-4 mb-3 mt-1 focus:outline-primary rounded-xl border border-gray-300"
          />
          <div className="text-sm text-red-500">
            <ErrorMessage name="last_name" />
          </div>
        </div>
        <div className="w-6/12 max-md:w-full px-2 my-2">
          <label htmlFor="" className="text-sm text-secondary font-medium">
            Phone*
          </label>
          <Field
            name="phone"
            type="number"
            variant="outlined"
            placeholder="Phone"
            className="w-full p-2 px-4 mb-3 mt-1 focus:outline-primary rounded-xl border border-gray-300"
          />
          <div className="text-sm text-red-500">
            <ErrorMessage name="phone" />
          </div>
        </div>
        <div className="w-6/12 max-md:w-full px-2 my-2">
          <label htmlFor="" className="text-sm text-secondary font-medium">
            Email*
          </label>
          <Field
            name="email"
            type="email"
            variant="outlined"
            placeholder="Email"
            className="w-full p-2 px-4 mb-3 mt-1 focus:outline-primary rounded-xl border border-gray-300"
          />
          <div className="text-sm text-red-500">
            <ErrorMessage name="email" />
          </div>
        </div>
        <div className="w-6/12 max-md:w-full px-2 my-2">
          <label htmlFor="" className="text-sm text-secondary font-medium">
            Password*
          </label>
          <Field
            name="password"
            type="password"
            variant="outlined"
            placeholder="Password"
            className="w-full p-2 px-4 mb-3 mt-1 focus:outline-primary rounded-xl border border-gray-300"
          />
          <div className="text-sm text-red-500">
            <ErrorMessage name="password" />
          </div>
        </div>
        <div className="w-6/12 max-md:w-full px-2 my-2">
          <label htmlFor="" className="text-sm text-secondary font-medium">
            Confirm Password*
          </label>
          <Field
            name="confirmPassword"
            type="password"
            variant="outlined"
            placeholder="Confirm Password"
            className="w-full p-2 px-4 mb-3 mt-1 focus:outline-primary rounded-xl border border-gray-300"
          />
          <div className="text-sm text-red-500">
            <ErrorMessage name="confirmPassword" />
          </div>
        </div>
      </div>

      <div
        id="agree-content"
        className="flex gap-3 px-2 my-5 text-sm text-[#4C4C4C] font-medium items-center"
      >
        <Field
          type="checkBox"
          className="w-6 h-6 rounded-full"
          name="termsConditions"
        />
        <p>
          I agree to Iguardu{" "}
          <span className="text-primary">terms & conditions</span>, the quote{" "}
          <span className="text-primary">toolterms & conditions</span> , and{" "}
          <br /> the data{" "}
          <span className="text-primary">sharing agreement</span>.
        </p>
      </div>
      <div className="text-sm text-red-500">
        <ErrorMessage name="termsConditions" />
      </div>

      <p className="text-sm font-medium w-full text-secondary text-center">
        Already have an account?{" "}
        <Link className="text-sm font-medium text-primary" to="/sign-in">
          Log in
        </Link>{" "}
      </p>
    </div>
  );
};

export default ProcessFive;
