import React from "react";
import goal_1 from "../../../assets/images/how-it-works/goal_1.webp";
import goal_2 from "../../../assets/images/how-it-works/goal_2.webp";
import goal_3 from "../../../assets/images/how-it-works/goal_3.webp";
import { motion } from "framer-motion";

const _inital = {
  y: "20%",
  opacity: 0,
  scale: 0.8,
};
const _viewport = { once: true };

const _whileInView = {
  y: 1,
  opacity: 1,
  scale: 1,
  transition: { duration: 0.8 },
};

function Goal({ toggle }) {
  return (
    <div className="flex flex-col items-center pb-[100px] pt-[50px] sm:pt-[100px] ">
      <motion.span
        initial={_inital}
        whileInView={{
          ..._whileInView,
          transition: {
            duration: 0.8,
          },
        }}
        viewport={_viewport}
        className="_h1_top"
      >
        {!toggle ? "Our Goal Is Simple" : "What We Do"}
      </motion.span>
      <motion.h1
        initial={_inital}
        viewport={_viewport}
        whileInView={_whileInView}
        className=" text-[#272a3f] font-medium text-[clamp(42px,calc(70/1340*100vw),70px)] leading-[1] text-center max-w-[1120px] mx-auto mt-[30px]"
      >
        {!toggle
          ? "Simplify your search for security roles, effortlessly"
          : "Transforming Your Security Recruitment Experience"}
      </motion.h1>
      {toggle ? (
        <motion.img
          initial={_inital}
          viewport={_viewport}
          whileInView={_whileInView}
          src={goal_3}
          alt="goal_3"
          className="w-full my-[30px]"
        />
      ) : (
        <div className="flex flex-col lg:flex-row items-center mt-[50px] gap-[40px] justify-between lg:mt-[100px] ">
          <motion.img
            initial={{ x: "-40%", opacity: 0 }}
            whileInView={{
              x: 0,
              opacity: 1,
              transition: {
                delay: 0.2,
                duration: 0.8,
              },
            }}
            viewport={_viewport}
            className="w-full lg:w-[clamp(42px,calc(550/1340*100vw),550px)] max-w-[550px]"
            src={goal_1}
            alt="goal_1"
          />
          <SvgArrow />
          <motion.img
            initial={{ x: "40%", opacity: 0 }}
            whileInView={{
              x: 0,
              opacity: 1,
              transition: {
                delay: 0.4,
                duration: 0.8,
              },
            }}
            viewport={_viewport}
            className="w-full lg:w-[clamp(42px,calc(550/1340*100vw),550px)] max-w-[550px]"
            src={goal_2}
            alt="goal_2"
          />
        </div>
      )}
      <div className="mt-[50px] flex flex-col items-center">
        {toggle ? null : (
          <motion.small
            initial={_inital}
            viewport={_viewport}
            whileInView={_whileInView}
            className="_h1_top"
          >
            Say good bye to traditional job posting
          </motion.small>
        )}

        <motion.h2
          initial={_inital}
          viewport={_viewport}
          whileInView={_whileInView}
          className="_h1"
        >
          {toggle
            ? "Not Your Average Hiring Platform"
            : "Embrace an elevated approach to security recruitment with Igardu"}
        </motion.h2>
        <motion.p
          initial={_inital}
          viewport={_viewport}
          whileInView={_whileInView}
          className="_p"
        >
          {toggle
            ? "Target and find your top security professionals effortlessly. Access exclusive tools to streamline your search and build a robust recruiting pipeline with Igardu"
            : "Experience a more efficient and effective way to manage your security recruitment process with our innovative platform."}
        </motion.p>
      </div>
    </div>
  );
}

export default Goal;

const SvgArrow = () => {
  return (
    <motion.svg
      className={
        "rotate-[220deg] lg:rotate-[135deg] mb-[30px] lg:mb-0 mr-0 lg:mr-[30px]"
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="50"
      height="50"
      preserveAspectRatio="xMidYMid slice"
    >
      <defs>
        <clipPath id="__lottie_element_98">
          <rect width="50" height="50" x="0" y="0"></rect>
        </clipPath>
      </defs>
      <g clipPath="url(#__lottie_element_98)">
        <g transform="matrix(1,0,0,1,-1,0)" opacity="1">
          <g opacity="1" transform="matrix(1,0,0,1,12,12)">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              fillOpacity="0"
              stroke="rgb(0,0,0)"
              strokeOpacity="1"
              strokeWidth="2"
              d=" M-7,7 C-7,7 -7,-7 -7,-7 C-7,-7 7,-7 7,-7"
            ></path>
          </g>
        </g>
        <g transform="matrix(1,0,0,1,0,0)" opacity="1">
          <g opacity="1" transform="matrix(1,0,0,1,25.5,24.5)">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              fillOpacity="0"
              stroke="rgb(0,0,0)"
              strokeOpacity="1"
              strokeWidth="2"
              d=" M-20.5,-18.64299964904785 C-13.166000366210938,-11.152000427246094 -7.927999973297119,-0.5 4.456999778747559,-0.5 C15.281000137329102,-0.5 20.5,-7.436999797821045 20.5,-11.880999565124512 C20.5,-16.64299964904785 17.97100067138672,-19.5 12.871999740600586,-19.5 C4.616000175476074,-19.5 -2.384000062942505,-10.02400016784668 -1.968999981880188,-1.4520000219345093 C-1.4299999475479126,10.928999900817871 8.85099983215332,19.5 19.547000885009766,19.5"
            ></path>
          </g>
        </g>
        <g transform="matrix(1,0,0,1,0,0)" opacity="1">
          <g opacity="1" transform="matrix(1,0,0,1,25.5,24.5)">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              fillOpacity="0"
              stroke="rgb(0,0,0)"
              strokeOpacity="1"
              strokeWidth="2"
              d=" M-20.5,-18.64299964904785 C-13.166000366210938,-11.152000427246094 -7.927999973297119,-0.5 4.456999778747559,-0.5 C15.281000137329102,-0.5 20.5,-7.436999797821045 20.5,-11.880999565124512 C20.5,-16.64299964904785 17.97100067138672,-19.5 12.871999740600586,-19.5 C4.616000175476074,-19.5 -2.384000062942505,-10.02400016784668 -1.968999981880188,-1.4520000219345093 C-1.4299999475479126,10.928999900817871 8.85099983215332,19.5 19.547000885009766,19.5"
            ></path>
          </g>
        </g>
        <g transform="matrix(1,0,0,1,-1,0)" opacity="1">
          <g opacity="1" transform="matrix(1,0,0,1,12,12)">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              fillOpacity="0"
              stroke="rgb(0,0,0)"
              strokeOpacity="1"
              strokeWidth="2"
              d=" M-7,7 C-7,7 -7,-7 -7,-7 C-7,-7 7,-7 7,-7"
            ></path>
          </g>
        </g>
      </g>
    </motion.svg>
  );
};
