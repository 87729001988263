import React, { useState } from "react";
import InputField from "./InputField";
import Radio from "./Radio";
import { ErrorMessage, Field } from "formik";

const ProgressEight = () => {
  const [agreements, setAgreements] = useState([]);

  return (
    <div className="w-full bg-white ">
      <div className="bg-primary w-full py-6">
        <div className="w-full md:w-7/12 mx-auto text-white">
          <h1 className="text-4xl nekst my-5">Sign up to be a trade member</h1>
          <p className="font-light">
            Igardu supports tradespeople with high standards.Once the account is
            created you will be published as unverified,once we run our checks
            you will be verified and after reccomended.
          </p>
        </div>
      </div>

      <div className="mt-10 w-full min-h-[600px] overflow-auto ">
        <div className="w-full p-4 md:w-1/2 mx-auto">
          <div className="flex w-full mt-2 justify-center items-center">
            <div className="w-1/2 pr-2">
              <label htmlFor="" className="text-sm text-secondary font-medium">
                First Name*
              </label>
              <Field
                type="text"
                variant="outlined"
                placeholder="First Name"
                name="first_name"
                className="w-full p-2 px-4 mb-3 mt-1 focus:outline-primary rounded-xl border border-gray-300"
              />
              <div className="text-sm text-red-500">
                <ErrorMessage name="first_name" />
              </div>
            </div>
            <div className="w-1/2 pl-2">
              <label htmlFor="" className="text-sm text-secondary font-medium">
                Last Name*
              </label>
              <Field
                name="last_name"
                type="text"
                variant="outlined"
                placeholder="Last Name"
                className="w-full p-2 px-4 mb-3 mt-1 focus:outline-primary rounded-xl border border-gray-300"
              />
              <div className="text-sm text-red-500">
                <ErrorMessage name="last_name" />
              </div>
            </div>
          </div>

          <div className="flex w-full mt-2 justify-center items-center">
            <div className="w-1/2 pr-2">
              <label htmlFor="" className="text-sm text-secondary font-medium">
                Phone*
              </label>
              <Field
                name="phone"
                type="number"
                variant="outlined"
                placeholder="Phone"
                className="w-full p-2 px-4 mb-3 mt-1 focus:outline-primary rounded-xl border border-gray-300"
              />
              <div className="text-sm text-red-500">
                <ErrorMessage name="phone" />
              </div>
            </div>
            <div className="w-1/2 pl-2">
              <label htmlFor="" className="text-sm text-secondary font-medium">
                Email*
              </label>
              <Field
                name="email"
                type="email"
                variant="outlined"
                placeholder="Email"
                className="w-full p-2 px-4 mb-3 mt-1 focus:outline-primary rounded-xl border border-gray-300"
              />
              <div className="text-sm text-red-500">
                <ErrorMessage name="email" />
              </div>
            </div>
          </div>

          <div className="flex w-full mt-2 justify-center items-start">
            <div className="w-1/2 pr-2">
              <label htmlFor="" className="text-sm text-secondary font-medium">
                Password*
              </label>
              <Field
                name="password"
                type="password"
                variant="outlined"
                placeholder="Password"
                className="w-full p-2 px-4 mb-3 mt-1 focus:outline-primary rounded-xl border border-gray-300"
              />
              <div className="text-sm text-red-500">
                <ErrorMessage name="password" />
              </div>
              <p className="text-secondary text-sm">
                Must be atleast 8 characters
              </p>
            </div>
            <div className="w-1/2 pl-2">
              <label htmlFor="" className="text-sm text-secondary font-medium">
                Confirm Password*
              </label>
              <Field
                name="confirmPassword"
                type="password"
                variant="outlined"
                placeholder="Confirm Password"
                className="w-full p-2 px-4 mb-3 mt-1 focus:outline-primary rounded-xl border border-gray-300"
              />
              <div className="text-sm text-red-500">
                <ErrorMessage name="confirmPassword" />
              </div>
            </div>
          </div>

          <div className="w-full flex mt-6 text-sm items-center gap-2">
            <Field name="news" type="checkbox" className="h-5 w-5"
            />{" "}
            <p>I’d like to receive Iguardu news, Advice and Tips</p>
          </div>
          <div className="w-full mt-2 text-sm flex items-center gap-2">
          <Field name="tc" type="checkbox" className="h-5 w-5"
            />
            <p className="w-11/12">
              I agree to Iguardu {" "}
              <span className="text-primary font-medium">
                terms & conditions
              </span>{" "}
              , the quote{" "}
              <span className="text-primary font-medium">
                toolterms & conditions
              </span>{" "}
              , and the data{" "}
              <span className="text-primary font-medium">
                sharing agreement{" "}
              </span>{" "}
            </p>
          </div>
          <div className="text-sm text-red-500">
                <ErrorMessage name="tc" />
              </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressEight;
