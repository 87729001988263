import React from "react";
import steps_5 from "../../../assets/images/how-it-works/step_5.webp";
import steps_6 from "../../../assets/images/how-it-works/step_6.webp";
import steps_4 from "../../../assets/images/how-it-works/step_4.webp";
import { motion } from "framer-motion";

const _inital = {
  y: "20%",
  opacity: 0,
  scale: 0.8,
};
const _viewport = { once: true };

const _whileInView = {
  y: 1,
  opacity: 1,
  scale: 1,
  transition: { duration: 0.8 },
};

export function Step2Recruiter({ toggle }) {
  return (
    <>
      <div className="flex flex-col items-center   py-[50px] sm:py-[100px] ">
        <div className="flex gap-0 sm:gap-[20px] lg:gap-[40px] items-start flex-col-reverse lg:flex-row justify-center max-w-[1260px] mx-auto w-full ">
          <div className="mx-auto lg:mx-0 mt-0 lg:mt-[50px] flex flex-col items-center lg:items-start">
            <motion.span
              initial={_inital}
              viewport={_viewport}
              whileInView={_whileInView}
              className="text-[#4c3cff]   text-[18px] sm:text-[24px] text-center lg:text-left max-w-[343px]">
              Step 2
            </motion.span>
            <motion.h2
              initial={_inital}
              viewport={_viewport}
              whileInView={_whileInView}
              className="max-w-[580px] text-[#272a3f] text-center lg:text-left font-medium text-[clamp(42px,calc(62/1340*100vw),62px)] leading-[1.3]  my-[10px] sm:my-[20px]">
              Sort responses with custom filters
            </motion.h2>
            <motion.p
              initial={_inital}
              viewport={_viewport}
              whileInView={_whileInView}
              className="max-w-[500px] text-[#3b4147] text-center lg:text-left font-[300] text-[clamp(16px,calc(20/1340*100vw),20px)] leading-[1.5] ">
              Leverage advanced filters to effortlessly source the most
              qualified candidates. Easily fine tune your search to find your
              perfect fit.
            </motion.p>
          </div>

          <motion.img
            initial={{ x: "40%", opacity: 0 }}
            whileInView={{
              x: 0,
              opacity: 1,
              transition: {
                delay: 0.2,
                duration: 0.8,
              },
            }}
            src={steps_5}
            alt="steps_5"
            className="max-w-[560px] lg:max-w-[clamp(42px,calc(500/1340*100vw),500px)] w-full mx-auto lg:mx-0"
          />
        </div>
      </div>
    </>
  );
}

export function Step3Recruiter({ toggle }) {
  return (
    <>
      <div className="flex flex-col items-center   py-[50px] sm:py-[100px] ">
        <motion.span
          initial={_inital}
          viewport={_viewport}
          whileInView={_whileInView}
          className="text-[#4c3cff]  text-[18px] sm:text-[24px] text-center lg:text-left max-w-[343px]">
          Step 3
        </motion.span>
        <motion.h1
          initial={_inital}
          viewport={_viewport}
          whileInView={_whileInView}
          className=" font-bold text-[#272a3f]  text-[clamp(42px,calc(80/1340*100vw),80px)] leading-[0.95] text-center max-w-[900px] mx-auto my-[30px]">
          Track all your top candidates in one place
        </motion.h1>
        <motion.p
          initial={_inital}
          viewport={_viewport}
          whileInView={_whileInView}
          className="max-w-[580px] text-[#3b4147] text-center  font-[300] text-[clamp(16px,calc(20/1340*100vw),20px)] leading-[1.5]">
          Tailor your candidate pool to the highest quality with access to over
          5,000 students from top universities. Track the status of your
          favorites in your dashboard.
        </motion.p>

        <motion.img
          initial={_inital}
          viewport={_viewport}
          whileInView={_whileInView}
          src={steps_6}
          alt="steps_6"
          className="max-w-[1200px]   w-full mx-auto mt-[20px] lg:mt-[50px] "
        />
      </div>
    </>
  );
}

export function StepHeader({ toggle }) {
  return (
    <>
      <div className="flex flex-col items-center   py-[50px] sm:pt-[100px] ">
        <motion.span
          initial={_inital}
          viewport={_viewport}
          whileInView={_whileInView}
          className="_h1_top">
          How It Works
        </motion.span>
        <motion.h1
          initial={_inital}
          viewport={_viewport}
          whileInView={_whileInView}
          className="_h1">
          {toggle ? "Few Easy Steps and Done" : "How it works, step by step"}
        </motion.h1>

        {toggle ? (
          <p className="_p">
            Here are the steps to set up your igardu profile, whether you're an
            artisan looking for work or an individual seeking an craftsmen.
          </p>
        ) : null}
      </div>
    </>
  );
}

export function Step1Recruiter({ toggle }) {
  return (
    <>
      <div className="flex flex-col items-center   py-[50px] sm:py-[100px] ">
        <div className="px-[20px] py-[10px] rounded-[20px] border border-[#ebecf2] text-[#667084]">
          <span>How It Works</span>
        </div>
        <motion.h1
          initial={_inital}
          viewport={_viewport}
          whileInView={_whileInView}
          className=" text-[#272a3f] font-medium text-[clamp(52px,calc(100/1340*100vw),100px)] leading-[0.95] text-center max-w-[900px] mx-auto mt-[30px]">
          How it works, step by step
        </motion.h1>

        <div className="flex gap-0 sm:gap-[20px] lg:gap-[40px] items-start flex-col-reverse lg:flex-row-reverse justify-center max-w-[1260px] mx-auto w-full mt-[70px] lg:mt-[100px]">
          <div className="mx-auto lg:mx-0 mt-0 lg:mt-[50px] flex flex-col items-center lg:items-start">
            <motion.span
              initial={_inital}
              viewport={_viewport}
              whileInView={_whileInView}
              className="text-[#4c3cff]   text-[18px] sm:text-[24px] text-center lg:text-left max-w-[343px]">
              Step 1
            </motion.span>
            <motion.h2
              initial={_inital}
              viewport={_viewport}
              whileInView={_whileInView}
              className="max-w-[580px] text-[#272a3f] text-center lg:text-left font-medium text-[clamp(42px,calc(62/1340*100vw),62px)] leading-[1.3]  my-[10px] sm:my-[20px]">
              Post your jobs and upcoming events
            </motion.h2>
            <motion.p
              initial={_inital}
              viewport={_viewport}
              whileInView={_whileInView}
              className="max-w-[400px] text-[#3b4147] text-center lg:text-left font-[300] text-[clamp(16px,calc(20/1340*100vw),20px)] leading-[1.5] ">
              Seamlessly add postings for events and roles you’re looking to
              fill.
            </motion.p>
          </div>

          <motion.img
            initial={{ x: "-40%", opacity: 0 }}
            whileInView={{
              x: 0,
              opacity: 1,
              transition: {
                delay: 0.2,
                duration: 0.8,
              },
            }}
            src={steps_4}
            alt="steps_4"
            className="max-w-[560px] lg:max-w-[clamp(42px,calc(500/1340*100vw),500px)] w-full mx-auto lg:mx-0"
          />
        </div>
      </div>
    </>
  );
}
