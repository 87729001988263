import React, { useEffect, useRef, useState } from "react";
import "./GeneralChat.css";
import { IoMdClose } from "react-icons/io";
import { IoMdSend } from "react-icons/io";
import { formatText } from "../../../utils/Util";
import { useDispatch, useSelector } from "react-redux";
import {
  getMessagesAction,
  sendMessageAction,
} from "../../../redux/job/action";
import moment from "moment";

const GeneralChat = ({ isOpen, onClose, selectedJob, role }) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const chatBodyRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const { getMessagesData, getMessagesLoading } = useSelector(
    (state) => state.JobSection
  );
  const { postLogin } = useSelector((state) => state.Authsection);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [loadingOlderMessages, setLoadingOlderMessages] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(true); // Track if more messages are available

  // useEffect(() => {
  //   if (isOpen) {
  //     setPage(1); // Reset page
  //     setMessages([]); // Clear messages
  //     setHasMoreMessages(true); // Reset hasMoreMessages
  //     inputRef.current?.focus(); // Focus input
  //   }
  // }, [isOpen]);

  useEffect(() => {
    if (getMessagesData && getMessagesData.success) {
      // console.log(getMessagesData, "get messages");

      if (page === 1) {
        setMessages(getMessagesData.data.messages.reverse());
      } else {
        setMessages((prevMessages) => [
          ...getMessagesData.data.messages.reverse(),
          ...prevMessages,
        ]);
      }

      // if (getMessagesData.data.messages.length < limit) {
      //   setHasMoreMessages(false); // No more messages to load
      // }
    }
  }, [getMessagesData]);

  useEffect(() => {
    const payload = {
      job_id: selectedJob.job_id ? selectedJob.job_id : selectedJob.id,
      user_type: role,
      page: page,
      limit: limit,
    };
    dispatch(getMessagesAction(payload));
  }, [page]);

  useEffect(() => {
    if (isOpen) {
      inputRef.current.focus();
    }
  }, [page, isOpen]);

  useEffect(() => {
    if (chatBodyRef.current && page === 1) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSend = () => {
    if (input.trim()) {
      const userMessage = {
        data: {
          text: input,
        },
        job_id: selectedJob.job_id ? selectedJob.job_id : selectedJob.id,
        user_type: role,
      };

      const payload = {
        text: input,
        user_id: postLogin.id,
      };

      setMessages((prevMessages) => [...prevMessages, payload]);
      setInput("");
      dispatch(sendMessageAction(userMessage));
    }
  };

  const handleScroll = () => {
    if (
      chatBodyRef.current.scrollTop === 0 &&
      !loadingOlderMessages &&
      hasMoreMessages
    ) {
      setLoadingOlderMessages(true);
      setPage((prevPage) => prevPage + 1);
      setTimeout(() => {
        setLoadingOlderMessages(false);
      }, 500); // Simulate a delay before fetching the next page
    }
  };

  return (
    <div className={`general-chat-screen ${isOpen ? "open" : ""}`}>
      <div className="chat-header">
        <span>{selectedJob.title}</span>
        <button onClick={onClose}>
          <IoMdClose size={25} color="white" />
        </button>
      </div>
      <div className="chat-body" ref={chatBodyRef} onScroll={handleScroll}>
        {getMessagesLoading && page === 1 ? (
          <div className="flex justify-center items-center h-full">
            <img
              src={"/loading-theme.svg"}
              alt="loading"
              className="w-20 h-20"
            />
          </div>
        ) : messages.length > 0 ? (
          <>
            {loadingOlderMessages && (
              <div className="flex justify-center items-center">
                <p>Loading older messages...</p>
              </div>
            )}
            {messages.map((msg, index) => (
              <div key={index} className="chat-item">
                <div
                  className="chat-message"
                  style={{
                    backgroundColor:
                      msg.user_id === postLogin?.id ? "#007bff" : "#f1f1f1",
                    alignSelf:
                      msg.user_id === postLogin?.id ? "flex-end" : "flex-start",
                    color: msg.user_id === postLogin?.id ? "white" : "black",
                  }}
                >
                  <p>{msg.text}</p>
                </div>
                <span
                  className="timestamp"
                  style={{
                    fontSize: "10px",
                    alignSelf:
                      msg.user_id === postLogin?.id ? "flex-end" : "flex-start",
                  }}
                >
                  {moment(msg.created_at).format("MMM Do YYYY, h:mm:ss a")} •{" "}
                  {msg.user_name
                    ? msg.user_name
                    : postLogin.first_name + postLogin.last_name}{" "}
                  • {msg.role ? msg.role : postLogin.role}
                </span>
              </div>
            ))}
          </>
        ) : (
          <div className="flex justify-center items-center h-full">
            <p>No discussion yet!</p>
          </div>
        )}
      </div>

      <div className="chat-input">
        <input
          ref={inputRef}
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSend()}
          placeholder="Type a message..."
        />
        <button onClick={handleSend} className="active-send">
          <IoMdSend size={25} color="white" />
        </button>
      </div>
    </div>
  );
};

export default GeneralChat;
