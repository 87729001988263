import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
// import { poppins } from "@/components/fonts";
import { Backdrop, CircularProgress } from "@mui/material";
import * as Yup from "yup";
import { FcGoogle } from "react-icons/fc";
import {
  getRole,
  getToken,
  postLoginAction,
  setRole,
  setToken,
} from "../../../redux/auth/action";
import { getProfileAction, setName } from "../../../redux/profile/action";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { Field, Form, Formik } from "formik";

const SignIn = () => {
  const dispatch = useDispatch();
  const router = useNavigate();

  const { token } = useSelector((state) => state.Authsection);
  const { role } = useSelector((state) => state.Authsection);
  const { postLogin } = useSelector((state) => state.Authsection);
  const { postLoginLoading } = useSelector((state) => state.Authsection);
  const { getProfile } = useSelector((state) => state.ProfileSection);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [load, setload] = useState(false);

  useEffect(() => {
    dispatch(getToken());
    dispatch(getRole());
  }, [dispatch]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (token !== null && role !== undefined && load === false) {
        if (role === "client") {
          router("/client/dashboard");
        } else if (role === "guard") {
          router("/guard/dashboard");
        } else if (role === "admin") {
          router("/dashboard");
        } else if(role === 'subadmin'){
          router("/subadmin/dashboard")
        }
      }
    }
  }, [token, role, load, router]);

  const initialValues = {
    email: localStorage.getItem("email") || "",
    password: localStorage.getItem("password") || "",
    remember: false,
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please provide the email properly")
      .required("Please provide your email"),
    password: Yup.string().required("Please provide your Password"),
  });

  useEffect(() => {
    if (postLogin.success === true) {
      dispatch(setToken(postLogin.token));
      dispatch(setRole(postLogin.role));
      dispatch(getProfileAction(postLogin.token));
    } else if (postLogin.success === false) {
      // setLoading(false);
      setload(false);
      notifyFail(postLogin.message);
    }
  }, [dispatch, postLogin]);

  useEffect(() => {
    const loadDashboard = {
      guard: () => router("/guard/dashboard"),
      client: () => router("/client/dashboard"),
      admin: () => router("/dashboard"),
      subadmin: () => router("/subadmin/dashboard")
    };
    if (getProfile?.success === true) {
      dispatch(setRole(getProfile.data.role));
      dispatch(
        setName(getProfile.data.first_name + " " + getProfile.data.last_name)
      );
      notify("Login Successful");
      loadDashboard[role]();
    } else if (getProfile?.success === false) {
      setLoading(false);
      notifyFail(postLogin.error);
    }
  }, [dispatch, getProfile, postLogin.error, postLogin.role, router]);

  const handleSubmit = (values) => {
    const payload = {
      email: values.email,
      password: values.password,
    };

    if (values.remember) {
      localStorage.setItem("email", values.email);
      localStorage.setItem("password", values.password);
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }

    setload(true);
    dispatch(postLoginAction(payload));
  };

  const notify = (value) => {
    toast.success(value);
  };

  const notifyFail = (value) => {
    toast.error(value);
  };

  return (
    <div className="grid grid-cols-2 ">
      <div className="md:col-span-1 col-span-2">
        <div className="flex min-h-[100vh] overflow-hidden p-5 md:p-0 ">
          <div className="w-full xl:1/2  md:w-2/3 mx-auto">
            <div className="md:p-2 px-2 md:px-[10px] md:mt-20 lg:mx-[80px] ">
              <div className="flex flex-col items-center justify-center">
                <p className="nekst text-center  font-normal mt-[30px] md:mt-[40px] w-full lg:w-[441px] text-[25px] md:text-[30px] lg:text-[40px] leading-[30px] md:leading-[45px]  text-[#29273E]">
                  Welcome to Igardu
                </p>

                <p
                  className={
                    " text-center leading-[27px]  text-[#818186] font-normal mt-[20px] md:mt-2 lg:mt-4 w-[100%] text-sm max-w-full  lg:w-[441px]   mid-xl:w-[75%] "
                    // +  poppins.className
                  }
                >
                  Whether just starting out or looking to improve or grow your
                  business, our expert advice can help you on the road to
                  success.
                </p>

                {/* form */}
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}
                >
                  {({ setFieldValue, values, errors }) => (
                    <Form>
                      <div className="mt-3 w-full lg:w-[441px]  flex-col gap-2 flex">
                        <label
                          className={
                            "font-semibold text-sm text-secondary "
                            // +poppins.className
                          }
                        >
                          Email
                        </label>
                        <Field
                          name="email"
                          type="email"
                          placeholder="Enter email"
                          className="fieldCss"
                        />

                        <label
                          className={
                            "font-semibold text-sm text-secondary "
                            // + poppins.className
                          }
                        >
                          Password
                        </label>
                        <Field
                          name="password"
                          type={showPassword ? "text" : "password"}
                          className="fieldCss"
                          placeholder="Enter password"
                          autoComplete="current-password"
                        />

                        <div className="flex my-3 justify-between w-full items-center">
                          <div className="flex items-center gap-2">
                            <Field
                              type="checkbox"
                              name="remember"
                              id="remember"
                              className="w-4 h-4"
                            />
                            <label
                              htmlFor="remember"
                              className={
                                "font-semibold text-sm text-secondary "
                                // + poppins.className
                              }
                            >
                              Remember me
                            </label>
                          </div>
                          <Link
                            to="/forget-password"
                            className="flex items-end justify-end"
                          >
                            <p
                              className={
                                "text-sm text-primary font-semibold "
                                // +poppins.className
                              }
                            >
                              Forgot Password?
                            </p>
                          </Link>
                        </div>
                      </div>

                      {/* submit button */}

                      <button
                        disabled={postLoginLoading}
                        type="submit"
                        className={
                          "w-full font-semibold lg:w-[441px] text-[14px] text-white items-center p-3 mt-[25px] rounded-full bg-[#305CF1] border-[2px] hover:bg-white hover:border-primary hover:text-primary duration-300 "
                          // + poppins.className
                        }
                      >
                        Sign In
                      </button>
                    </Form>
                  )}
                </Formik>

                {/* <div className="flex items-center justify-center mt-[25px]">
                  <div className=" w-[50px] md:w-[100px] lg:w-[141px] h-[2px] bg-[#E2E1EB]"></div>
                  <p className="text-[12px] mx-[10px] leading-[18px] whitespace-nowrap text-[#646378] font-normal">
                    Or Sign up with{" "}
                  </p>
                  <div className="w-[50px] md:w-[100px] lg:w-[141px] h-[2px] bg-[#E2E1EB]"></div>
                </div> */}

                {/* <button
                  className={
                    "w-full lg:w-[441px]  text-[14px] flex gap-4 items- justify-center px-4 py-2 mt-[25px] rounded-full border-[2px] text-secondary hover:border-[#305CF1] hover:text-primary border-[#A4A4AA] font-semibold duration-300 "
                    // + poppins.className
                  }
                >
                  <FcGoogle
                    className="w-[22px] h-[22px] object-cover "
                    alt="google 1"
                  />
                  Sign In With Google
                </button> */}

                {/* <Link to="/sign-up">
                  <p
                    className={
                      " text-center mt-[30px] text-[18px] text-[#B9B8C0] leading-[27px] text-sm font-medium "
                      // +poppins.className
                    }
                  >
                    Don't have an account?{" "}
                    <span className="text-primary font-semibold">Sign up </span>
                  </p>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-blue-500 min-h-screen items-center justify-center md:flex hidden">
        <Link to="/">
          <img src={logo} alt="" width={200} height={100} />
        </Link>
        {/* Content of your component */}
      </div>
      {loading || postLoginLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading || postLoginLoading}
          // onClick={handleClose}
        >
          <CircularProgress style={{ color: "#083558" }} />
        </Backdrop>
      ) : null}
    </div>
  );
};

export default SignIn;
