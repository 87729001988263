import React from "react";
import Header from "./header";

import Navbar from "../../pages/main/homepage/components/navbar";

import Footer from "../../pages/main/homepage/components/footer";

import "./styles.css";

const TermsAndConditions = ({ toggle }) => {
  // const [toggle, setToggle] = useState(false);

  return (
    <>
      <div
        className={`w-full bg-gradient-to-r ${toggle ? "from-[#023BA9] to-[#0B0D1F]" : "from-[#145DEB] to-[#0B0D1F]"}  px-[20px] pt-[20px] relative overflow-x-hidden `}>
        {/* <div
          className="absolute top-0 left-0 h-full w-full z-[1]"
          style={{
            background:
              "url(http://www.script-tutorials.com/demos/360/images/stars.png) repeat top center",
          }}
        /> */}
        <Navbar transparent />

        <div className="max-w-[1340px] mx-auto relative z-[10] mt-[50px]">
          {/* <Nav toggle={toggle} /> */}
          <Header />
        </div>
      </div>
      <div className={`w-full  px-[20px] pt-[20px] `}>
        <div className="max-w-[1340px] mx-auto py-[50px] trms_condition">
          <h1>Cookies Policy</h1>

          <p>
            This Cookies Policy explains what cookies are, how we use cookies,
            how third-parties we may partner with may use cookies on the
            Service, your choices regarding cookies, and further information
            about cookies.
          </p>

          <h2>What are cookies</h2>
          <p>
            Cookies are small pieces of text sent by your web browser by a
            website you visit. A cookie file is stored in your web browser and
            allows the Service or a third-party to recognize you and make your
            next visit easier and the Service more useful to you.
          </p>

          <h2>How Igardu uses cookies</h2>
          <p>
            When you use and access the Service, we may place a number of
            cookies files in your web browser. We use cookies for the following
            purposes:
          </p>
          <ul>
            <li>To enable certain functions of the Service.</li>
            <li>To provide analytics.</li>
            <li>To store your preferences.</li>
            <li>
              To enable advertisements delivery, including behavioral
              advertising.
            </li>
          </ul>

          <h2>What are your choices regarding cookies</h2>
          <p>
            If you'd like to delete cookies or instruct your web browser to
            delete or refuse cookies, please visit the help pages of your web
            browser. Please note, however, that if you delete cookies or refuse
            to accept them, you might not be able to use all of the features we
            offer, you may not be able to store your preferences, and some of
            our pages might not display properly.
          </p>

          <h2>Where can you find more information about cookies</h2>
          <p>
            You can learn more about cookies and the following third-party
            websites:
          </p>
          <ul>
            <li>
              AllAboutCookies:{" "}
              <a href="http://www.allaboutcookies.org/" target="_blank">
                http://www.allaboutcookies.org/
              </a>
            </li>
            <li>
              Network Advertising Initiative:{" "}
              <a href="http://www.networkadvertising.org/" target="_blank">
                http://www.networkadvertising.org/
              </a>
            </li>
          </ul>

          <h2>Changes to our cookies policy</h2>
          <p>
            We may update our Cookies Policy from time to time. We will notify
            you of any changes by posting the new Cookies Policy on this page.
          </p>

          <h2>Contact Us</h2>
          <p>
            If you have any questions about our Cookies Policy, please contact
            us at [Your Contact Information].
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default TermsAndConditions;
