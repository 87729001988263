import React, { useEffect, useState } from "react";
import SubAdminsTable from "../users/components/SubAdminsTable";
import { Close } from "@mui/icons-material";
import { Box, Drawer, Modal, FormControlLabel, Checkbox } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TiCameraOutline } from "react-icons/ti";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  addSubAdminAction,
  getSubAdminsAction,
} from "../../../redux/admin/action";
import MemberDetails from "../trade-members/components/memberDetails";
import SubAdminDetails from "./sections/SubAdminDetails";

const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
const uploadPreset = process.env.REACT_APP_CLOUDINARY_PRESET_NAME;

const SubAdmins = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.Authsection);
  const [open, setOpen] = useState(false);
  const [isModalOn, setIsModalOn] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const { addSubAdmin, addSubAdminLoading } = useSelector(
    (state) => state.Adminsection
  );
  const { getSubAdmins, getSubAdminsLoading } = useSelector((state) => 
    state.Adminsection
  );
  const [subAdmins, setSubAdmins] = useState([]);
  const [isSubAdminAdded, setIsSubAdminAdded] = useState(false);
  const [selectedSubAdmin, setSelectedSubAdmin] = useState(null)

  useEffect(() => {
    if (getSubAdmins && getSubAdmins.success) {
      setSubAdmins(getSubAdmins);
    }
  }, [getSubAdmins]);

  useEffect(() => {
    dispatch(getSubAdminsAction());
  }, []);

  const handleClose = () => {
    setIsModalOn(false);
  };

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    countryCode: "",
    phone: "",
    password: "",
    accessToUsers: false,
    accessToJobs: false,
    accessToTradeMembers: false,
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
  };

  useEffect(() => {
    if (addSubAdmin && addSubAdmin.success && isSubAdminAdded) {
      toast.success(addSubAdmin.message);
      dispatch(getSubAdminsAction());
      setIsModalOn(false);
      setIsSubAdminAdded(false);
    }else if(addSubAdmin && !addSubAdmin.success && isSubAdminAdded){
      toast.error(addSubAdmin.message);
      setIsSubAdminAdded(false)
    }
  }, [addSubAdmin]);

  const onSubmit = (values) => {
    const payload = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone: values.phone,
      country_code: values.countryCode ? values.countryCode : "+44",
      profile_picture: profileImage,
      password: values.password,
      permissions: {
        client: values.accessToUsers ? values.accessToUsers : false,
        job: values.accessToJobs ? values.accessToJobs : false,
        guard: values.accessToTradeMembers ? values.accessToTradeMembers : false,
      },
    };

    console.log(payload);
   
    dispatch(addSubAdminAction(payload));
    setIsSubAdminAdded(true);
  };

  const uploadAvatar = async ({ event }) => {
    const files = event.target.files;
    try {
      if (files && files?.length > 0) {
        const _selectedFile = files[0];
        setProfileImage(URL.createObjectURL(_selectedFile));
        console.log(_selectedFile, "selected image");
        const fileSizeInMB = _selectedFile.size / (1024 * 1024);
        if (fileSizeInMB > 2) {
          alert("File size should be 2MB or less.");
          return;
        }

        const formData = new FormData();
        formData.append("file", _selectedFile);
        formData.append("upload_preset", uploadPreset);
        axios
          .post(`https://api.cloudinary.com/v1_1/${cloudName}/upload`, formData)
          .then((res) => {
            console.log(res, "image upload");
            const payload = {
              url: res.data.secure_url,
              name: "profile_pic",
            };
            setProfileImage(res.data.secure_url);
          })
          .catch((err) => console.log(err));
      }
    } catch (error) {
      toast.error("Error updating profile image");
    }
  };

  return (
    <>
      <Drawer
        anchor={"right"}
        sx={{
          width: "70%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "70%",
            boxSizing: "border-box",
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
        className="dashboard"
      >
        <div className="bg-white rounded-xl w-full p-10">
          <div className="flex items-center justify-end w-full">
            <div
              onClick={() => {
                setOpen(false);
                setSelectedSubAdmin(null);
              }}
              className="w-12 h-12 rounded-full hover:bg-[#145DEB42] px-2 py-3 cursor-pointer font-semibold text-center bg-[#145DEB1A] text-primary"
            >
              <Close />
            </div>
          </div>

          <SubAdminDetails currMember={selectedSubAdmin} />
        </div>
      </Drawer>

      <div className="w-full min-h-screen bg-dull py-6 md:px-10 px-2">
        <div className="flex justify-end">
          <button
            className="bg-[#0070de] hover:!bg-[#4585FF] text-white p-2 rounded-[5px] w-[230px]"
            onClick={() => setIsModalOn(true)}
          >
            Add Subadmin
          </button>
        </div>

        <SubAdminsTable
          subAdmins={subAdmins}
          subAdminsLoading={getSubAdminsLoading}
          setOpen={(data) => setOpen(data)}
          setSelectedSubAdmin={(data) => setSelectedSubAdmin(data)}
        />

        <Modal
          disableBackdropClick
          open={isModalOn}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="w-[80%] md:w-[50%]">
            <div>
              <div className="flex items-center justify-between">
                <div className="flex justify-center text-[18px] font-bold">
                  <p>Add Subadmin</p>
                </div>
                <div
                  onClick={() => setIsModalOn(false)}
                  className="w-12 h-12 rounded-full hover:bg-[#145DEB42] px-2 py-3 cursor-pointer font-semibold text-center bg-[#145DEB1A] text-primary"
                >
                  <Close />
                </div>
              </div>

              <div className="flex flex-col items-center">
                <div className="my-6 relative bg-[#E4E4E4] rounded-full w-[100px]">
                  <img
                    src={
                      profileImage ? profileImage : "/profile-image.webp"
                    }
                    alt="admin"
                    width={95}
                    height={95}
                    className="rounded-full object-cover profile_image"
                  />
                  <label
                    htmlFor="user_profile_avatar"
                    className="bg-[#366CFE] rounded-full p-2.5 cursor-pointer absolute bottom-0 right-0 mb-0.5"
                  >
                    <TiCameraOutline className="text-white" />
                  </label>
                </div>

                <input
                  type="file"
                  className="hidden"
                  id="user_profile_avatar"
                  accept="image/*"
                  onChange={(e) => {
                    uploadAvatar({ event: e });
                    e.target.value = null;
                  }}
                />
              </div>

              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
              >
                {() => (
                  <Form>
                    <div className="grid grid-cols-2 w-full gap-12 py-[30px]">
                      <div className="flex flex-col md:col-span-1 col-span-2">
                        <label
                          htmlFor="first-name"
                          className="text-[#999999] text-xs pl-1"
                        >
                          First Name
                        </label>
                        <Field
                          type="text"
                          id="first-name"
                          name="first_name"
                          className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
                        />
                      </div>
                      <div className="flex flex-col md:col-span-1 col-span-2">
                        <label
                          htmlFor="last-name"
                          className="text-[#999999] text-xs pl-1"
                        >
                          Last Name
                        </label>
                        <Field
                          type="text"
                          id="last-name"
                          name="last_name"
                          className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
                        />
                      </div>
                      <div className="flex flex-col md:col-span-1 col-span-2">
                        <label
                          htmlFor="email-address"
                          className="text-[#999999] text-xs pl-1"
                        >
                          Email Address
                        </label>
                        <Field
                          type="email"
                          id="email-address"
                          name="email"
                          className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
                        />
                      </div>
                      <div className="flex flex-col md:col-span-1 col-span-2">
                        <label
                          htmlFor="phone-no"
                          className="text-[#999999] text-[10px] pl-1"
                        >
                          Mobile phone
                        </label>
                        <div className="flex">
                          <Field
                            as="select"
                            name="countryCode"
                            className=" mr-4 focus:outline-none w-[80px] text-xs border-b-2 border-[#D8D8D8]"
                          >
                            <option>+44</option>
                            <option>+144</option>
                            <option>+91</option>
                            <option>+34</option>
                          </Field>
                          <Field
                            type="text"
                            id="phone-no"
                            name="phone"
                            className="w-full border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col md:col-span-1 col-span-2">
                        <label
                          htmlFor="password"
                          className="text-[#999999] text-[10px] pl-1"
                        >
                          Password
                        </label>
                        <Field
                          type="password"
                          id="password"
                          name="password"
                          className="w-full border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
                        />
                      </div>

                      {/* Add checkboxes for permissions */}
                      
                      <div className="flex flex-col col-span-2">
                      <p className="text-[#999999] text-[10px] pl-1">Permissions</p>
                      <div className="flex gap-4 items-center">
                      <FormControlLabel
                          control={
                            <Field
                              type="checkbox"
                              name="accessToUsers"
                              as={Checkbox}
                            />
                          }
                          label="Users"
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "black", 
                            },
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Field
                              type="checkbox"
                              name="accessToJobs"
                              as={Checkbox}
                            />
                          }
                          label="Jobs"
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "black", 
                            },
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Field
                              type="checkbox"
                              name="accessToTradeMembers"
                              as={Checkbox}
                            />
                          }
                          label="Trade Members"
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "black", 
                            },
                          }}
                        />
                      </div>
                       
                      </div>
                    </div>

                    <div className="flex flex-col md:col-start-2 md:col-span-1 col-span-2">
                      <button
                        type="submit"
                        className="bg-primary px-7 py-2.5 text-sm text-white justify-center flex items-center md:w-fit w-full rounded-md ml-auto"
                      >
                        {addSubAdminLoading ? (
                          <img
                            src={"/loading.svg"}
                            alt="loading"
                            className="w-6 h-6"
                          />
                        ) : (
                          <p>Add</p>
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default SubAdmins;
